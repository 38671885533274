import TodayIcon from '@mui/icons-material/Today';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import FeedIcon from '@mui/icons-material/Feed';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ImageIcon from '@mui/icons-material/Image';
import { CircularProgress } from '@mui/material';
import HubIcon from '@mui/icons-material/Hub';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import ExtensionIcon from '@mui/icons-material/Extension';
import GamesIcon from '@mui/icons-material/Games';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import LinkIcon from '@mui/icons-material/Link';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import LogoDevIcon from '@mui/icons-material/LogoDev';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CallIcon from '@mui/icons-material/Call';
import DiningIcon from '@mui/icons-material/Dining';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DiscountIcon from '@mui/icons-material/Discount';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import ForumIcon from '@mui/icons-material/Forum';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import RadioIcon from '@mui/icons-material/Radio';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import VerifiedIcon from '@mui/icons-material/Verified';
import ReportIcon from '@mui/icons-material/Report';
import SignalWifiStatusbar4BarIcon from '@mui/icons-material/SignalWifiStatusbar4Bar';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LoginIcon from '@mui/icons-material/Login';
import FaceIcon from '@mui/icons-material/Face';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ContactsIcon from '@mui/icons-material/Contacts';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import MenuIcon from '@mui/icons-material/Menu';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LogoutIcon from '@mui/icons-material/Logout';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WcIcon from '@mui/icons-material/Wc';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import HomeIcon from '@mui/icons-material/Home';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HardwareIcon from '@mui/icons-material/Hardware';
import SearchIcon from '@mui/icons-material/Search';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import AddIcon from '@mui/icons-material/Add';
import InputIcon from '@mui/icons-material/Input';
import SaveIcon from '@mui/icons-material/Save';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ReplayIcon from '@mui/icons-material/Replay';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import AssignmentIcon from '@mui/icons-material/Assignment';
import NoteIcon from '@mui/icons-material/Note';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import GoogleIcon from '@mui/icons-material/Google';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LanguageIcon from '@mui/icons-material/Language';
import HandshakeIcon from '@mui/icons-material/Handshake';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import DownloadIcon from '@mui/icons-material/Download';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import PublicIcon from '@mui/icons-material/Public';
import CheckIcon from '@mui/icons-material/Check';
import TabletIcon from '@mui/icons-material/Tablet';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SecurityIcon from '@mui/icons-material/Security';
import StoreIcon from '@mui/icons-material/Store';
import RemoveIcon from '@mui/icons-material/Remove';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AdbIcon from '@mui/icons-material/Adb';
export const getIcon = (value, color, fontSize, all) => {
    const iconStyle = {
        color: color,
        fontSize: fontSize,
    }
    const icons = {
        '1': <HubIcon sx={iconStyle} />,
        '2': <ArrowRightIcon sx={iconStyle} />,
        '3': <ElectricBoltIcon sx={iconStyle} />,
        '4': <ExtensionIcon sx={iconStyle} />,
        '5': <GamesIcon sx={iconStyle} />,
        '6': <HeadphonesIcon sx={iconStyle} />,
        '7': <InsertChartIcon sx={iconStyle} />,
        '8': <LinkIcon sx={iconStyle} />,
        '9': <LocalGroceryStoreIcon sx={iconStyle} />,
        '10': <LogoDevIcon sx={iconStyle} />,
        '11': <ManageAccountsIcon sx={iconStyle} />,
        '12': <CallIcon sx={iconStyle} />,
        '13': <DiningIcon sx={iconStyle} />,
        '14': <DirectionsCarIcon sx={iconStyle} />,
        '15': <DiscountIcon sx={iconStyle} />,
        '16': <FitnessCenterIcon sx={iconStyle} />,
        '17': <ForumIcon sx={iconStyle} />,
        '18': <FreeBreakfastIcon sx={iconStyle} />,
        '19': <NotificationsIcon sx={iconStyle} />,
        '20': <PhonelinkIcon sx={iconStyle} />,
        '21': <RadioIcon sx={iconStyle} />,
        '22': <RocketLaunchIcon sx={iconStyle} />,
        '23': <VerifiedIcon sx={iconStyle} />,
        '24': <ReportIcon sx={iconStyle} />,
        '25': <CircularProgress sx={iconStyle} />,
        '26': <SignalWifiStatusbar4BarIcon sx={iconStyle} />,
        '27': <EditIcon sx={iconStyle} />,
        '28': <DeleteIcon sx={iconStyle} />,
        '29': <LoginIcon sx={iconStyle} />,
        '30': <FaceIcon sx={iconStyle} />,
        '31': <ViewModuleIcon sx={iconStyle} />,
        '32': <GroupIcon sx={iconStyle} />,
        '33': <PersonIcon sx={iconStyle} />,
        '34': <PhotoCameraIcon sx={iconStyle} />,
        '35': <ContactsIcon sx={iconStyle} />,
        '36': <KeyboardArrowDownIcon sx={iconStyle} />,
        '37': <KeyboardArrowUpIcon sx={iconStyle} />,
        '38': <PhoneIphoneIcon sx={iconStyle} />,
        '39': <ContactEmergencyIcon sx={iconStyle} />,
        '40': <MenuIcon sx={iconStyle} />,
        '41': <LocalActivityIcon sx={iconStyle} />,
        '42': <PeopleAltIcon sx={iconStyle} />,
        '43': <LogoutIcon sx={iconStyle} />,
        '44': <WcIcon sx={iconStyle} />,
        '45': <ShoppingBasketIcon sx={iconStyle} />,
        '46': <HomeIcon sx={iconStyle} />,
        '47': <ViewInArIcon sx={iconStyle} />,
        '48': <InboxIcon sx={iconStyle} />,
        '49': <MailIcon sx={iconStyle} />,
        '50': <LocalShippingIcon sx={iconStyle} />,
        '51': <AccountBalanceIcon sx={iconStyle} />,
        '52': <HardwareIcon sx={iconStyle} />,
        '53': <SearchIcon sx={iconStyle} />,
        '54': <BackupTableIcon sx={iconStyle} />,
        '55': <AddIcon sx={iconStyle} />,
        '56': <InputIcon sx={iconStyle} />,
        '57': <SaveIcon sx={iconStyle} />,
        '58': <ExitToAppIcon sx={iconStyle} />,
        '59': <ReplayIcon sx={iconStyle} />,
        '60': <ImageIcon sx={iconStyle} />,
        '61': <FeedIcon sx={iconStyle} />,
        '62': <EditNoteIcon sx={iconStyle} />,
        '63': <EditNotificationsIcon sx={iconStyle} />,
        '64': <LeaderboardIcon sx={iconStyle} />,
        '65': <WorkspacePremiumIcon sx={iconStyle} />,
        '66': <CalendarMonthIcon sx={iconStyle} />,
        '67': <TodayIcon sx={iconStyle} />,
        '68': <AssignmentIcon sx={iconStyle} />,
        '69': <NoteIcon sx={iconStyle} />,
        '70': <DoneAllIcon sx={iconStyle} />,
        '71': <GoogleIcon sx={iconStyle} />,
        '72': <InstagramIcon sx={iconStyle} />,
        '73': <FacebookIcon sx={iconStyle} />,
        '74': <TwitterIcon sx={iconStyle} />,
        '75': <LanguageIcon sx={iconStyle} />,
        '76': <HandshakeIcon sx={iconStyle} />,
        '77': <OpenInFullIcon sx={iconStyle} />,
        '78': <DownloadIcon sx={iconStyle} />,
        '79': <InsertLinkIcon sx={iconStyle} />,
        '80': <ArrowLeftIcon sx={iconStyle} />,
        '81': <PublicIcon sx={iconStyle} />,
        '82': <CheckIcon sx={iconStyle} />,
        '83': <TabletIcon sx={iconStyle} />,
        '84': <FavoriteIcon sx={iconStyle} />,
        '85': <ShoppingCartCheckoutIcon sx={iconStyle} />,
        '86': <CreditCardIcon sx={iconStyle} />,
        '87': <SecurityIcon sx={iconStyle} />,
        '88': <StoreIcon sx={iconStyle} />,
        '89': <RemoveIcon sx={iconStyle} />,
        '90': <ReceiptIcon sx={iconStyle} />,
        '91': <AdbIcon sx={iconStyle} />,
    }
    if (all) return icons
    if (!icons.hasOwnProperty(value)) return <NotInterestedIcon sx={iconStyle} />
    return icons[value]
}