import React, { useState, useContext, useEffect } from 'react';
import { useTheme, Box, Modal, Typography, Button, Link } from '@mui/material';
import { User } from '../../../contexts/user';
import { UpComp } from '../../../components/upComp';
import { getIcon } from '../../../components/icons';
import { ButtonSubmit } from '../../../components/buttons';
import mainApi from '../../../apis/mainApiN';

export function Relatorios({ visible, handleRelatVisible, updateModalInfo }) {
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [selected, setSelected] = useState(null)


    async function gerarRelat() {
        updateModalInfo(true, false, 'Carregando', 'Aguarde! Estamos gerando seu relatório', 'loading')
        const getRelat = await mainApi(`produtosBase/relatorios/${selected}`, 'GET', null, user?.data?.token)
        if (getRelat?.status !== 200 || !getRelat?.apiReturn?.link) {
            const message = getRelat?.apiReturn?.message || 'Ocorreu um erro ao gerar o relatório'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        updateModalInfo(true, true, 'Sucesso', 'Seu arquivo está pronto.', 'oneButton', () => window.open(getRelat.apiReturn?.link, '_blank'))
    }

    function handleClick(idx) {
        console.log(idx)
        if (idx === 1) {
            handleRelatVisible(false)
        }
        if (idx === 0) {
            gerarRelat()
        }
    }

    return (
        <Modal open={visible} onClose={() => handleRelatVisible(false)} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: 420, width: 650, background: theme.palette.background.default, outline: 'none' }}>
                <Box sx={{ background: theme.palette.background.default, padding: 5, paddingBottom: 1 }}>
                    <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 30 }}>Relatórios</Typography>
                    <Typography sx={{ color: theme.palette.text.primary, fontSize: 18 }}>Escolha abaixo o seu relatório.</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 5, justifyContent: 'space-between', height: '100%', overflowY: 'scroll' }}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'center', alignItems: 'center', }}>
                        {relat.map((e, idx) => {
                            const color = selected === e?.id || theme.palette.mode === 'dark' ? theme.palette.text.whiteText : theme.palette.primary.main
                            const icon = getIcon(e?.icon, color, 50)
                            return (
                                <Button key={e.id} sx={{ textTransform: 'none', flexDirection: 'column', gap: 1 }} onClick={() => setSelected(e?.id)}>
                                    <UpComp tamanho={100} background={selected === e?.id ? theme.palette.secondary.main : 'transparent'}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
                                            {icon}
                                            <Typography sx={{ color: color, fontWeight: 'bold' }}>{e?.nome}</Typography>
                                        </Box>
                                    </UpComp>
                                </Button>
                            )
                        })}
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: 2, padding: 2 }}>
                    {['Gerar', 'Sair'].map((e, idx) => {
                        const background = e === 'Gerar' ? theme.palette.primary.main : theme.palette.background.paper
                        const color = e === 'Gerar' ? theme.palette.text.whiteText : theme.palette.text.primary
                        return (
                            <ButtonSubmit key={e} text={e} background={background} color={color} func={handleClick} funcParameters={idx} />
                        )
                    })}
                </Box>
            </Box>
        </Modal>
    );
}

const relat = [
    {
        id: 1,
        nome: 'Base',
        icon: 1
    },
    {
        id: 2,
        nome: 'Base SQL',
        icon: 91
    },
]