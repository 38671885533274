import React, { useState, useContext } from 'react';
import { useTheme, Box, Typography } from '@mui/material';
import { ModalInfo } from '../../components/modalInfoN';
import { User } from '../../contexts/user';
import { getEvento } from '../../components/getEvento';
import { Filter } from './components/filter';
import mainApi from '../../apis/mainApiN';
import { ListProdutos } from './components/listProdutos';
import { NovoProduto } from './components/novo';
import { EditProduto } from './components/edit';
import { Pendencias } from './components/pendencias';
import { EcomView } from './components/ecomView';
import { Relatorios } from './components/relatorios';

export function ProdBase(props) {
    document.title = 'Produtos Base - Central de Negócios'
    const theme = useTheme();
    const user = useContext(User);
    //estados
    const [MInfo, setMInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    const [produtos, setProdutos] = useState([])
    const [novoProduto, setNovoProduto] = useState(false)
    const [relatVisible, setRelatVisible] = useState(false)
    const [editProduto, setEditProduto] = useState({
        visible: false,
        produto: null
    })
    const [pendencias, setPendencias] = useState(false)
    const [ecomInfo, setEcomInfo] = useState({
        visible: false,
        produto: null
    })
    const [isPendEcom, setIsPendecom] = useState(false)

    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        const newMInfo = { ...MInfo };
        newMInfo.visible = visible;
        newMInfo.canDesactive = canDesactive;
        newMInfo.title = title;
        newMInfo.subtitle = subtitle;
        newMInfo.icon = icon;
        newMInfo.funcS = func;
        setMInfo(newMInfo);
    }
    async function getProdutos(value) {
        setIsPendecom(false)
        if (value.length === 0) {
            setProdutos([])
            return
        }
        setProdutos([])
        if (value.length < 3) {
            updateModalInfo(true, true, 'Atenção', 'Insira ao menos 3 caracteres para a pesquisa.', 'exclamation')
            return
        }
        updateModalInfo(true, false, 'Carregando', 'Buscando produtos no servidor.', 'loading')
        const get = await mainApi('produtosBase/buscar/' + value, 'GET', null, user?.data?.token)
        if (get?.status !== 200 || !Array.isArray(get?.apiReturn?.apiReturn)) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Não foi possível buscar os produtos.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        const getProdutos = get?.apiReturn?.apiReturn
        if (getProdutos.length === 0) {
            updateModalInfo(true, true, 'Atenção', 'Nenhum produto encontrado.', 'exclamation')
            return
        }
        setProdutos(getProdutos)
        updateModalInfo(false)
    }
    async function getMinhasAlterações() {
        setIsPendecom(false)
        setProdutos([])
        updateModalInfo(true, false, 'Carregando', 'Buscando produtos no servidor.', 'loading')
        const get = await mainApi('produtosBase/buscarMinhas', 'GET', null, user?.data?.token)
        if (get?.status !== 200 || !Array.isArray(get?.apiReturn?.apiReturn)) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Não foi possível buscar os produtos.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        const getProdutos = get?.apiReturn?.apiReturn
        if (getProdutos.length === 0) {
            updateModalInfo(true, true, 'Atenção', 'Nenhum produto encontrado.', 'exclamation')
            return
        }
        setProdutos(getProdutos)
        updateModalInfo(false)
    }
    async function getRevisoes() {
        setIsPendecom(false)
        setProdutos([])
        updateModalInfo(true, false, 'Carregando', 'Buscando produtos no servidor.', 'loading')
        const get = await mainApi('produtosBase/pendencias/revisions', 'GET', null, user?.data?.token)
        if (get?.status !== 200 || !Array.isArray(get?.apiReturn?.apiReturn)) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Não foi possível buscar os produtos.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        const getProdutos = get?.apiReturn?.apiReturn
        if (getProdutos.length === 0) {
            updateModalInfo(true, true, 'Atenção', 'Nenhum produto encontrado.', 'exclamation')
            return
        }
        setProdutos(getProdutos)
        updateModalInfo(false)
    }
    async function getEcomPend() {
        setIsPendecom(true)
        setProdutos([])
        updateModalInfo(true, false, 'Carregando', 'Buscando produtos no servidor.', 'loading')
        const get = await mainApi('produtosBase/pendencias/getEcomPend', 'GET', null, user?.data?.token)
        if (get?.status !== 200 || !Array.isArray(get?.apiReturn?.apiReturn)) {
            const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Não foi possível buscar os produtos.'
            updateModalInfo(true, true, 'Atenção', message, 'exclamation')
            return
        }
        const getProdutos = get?.apiReturn?.apiReturn
        if (getProdutos.length === 0) {
            updateModalInfo(true, true, 'Atenção', 'Nenhum produto encontrado.', 'exclamation')
            return
        }
        setProdutos(getProdutos)
        updateModalInfo(false)
    }
    function updateEditProduto(visible, produto) {
        const newE = { ...editProduto }
        newE.visible = visible ? visible : false
        newE.produto = produto ? produto : null
        setEditProduto(newE)
    }
    function pushProdutos(produto) {
        const newProduto = [...produtos]
        newProduto.unshift(produto)
        setProdutos(newProduto)
    }
    function visiblePendencias(visible) {
        setPendencias(visible)
    }
    function updateProduto(produto, reset) {
        if (reset) {
            getEcomPend()
            return
        }
        const newProduto = [...produtos]
        const findIndex = newProduto.findIndex((e) => e.cod_barra === produto.cod_barra)
        if (findIndex !== -1) {
            newProduto[findIndex] = produto
        }
        setProdutos(newProduto)
    }
    function handleNewProduto(visible) {
        setNovoProduto(visible)
    }
    function handleEcomInfo(visible, produto) {
        const newInfo = { ...ecomInfo }
        newInfo.visible = visible ? visible : false
        newInfo.produto = produto ? produto : null
        setEcomInfo(newInfo)
    }
    function handleRelatVisible(visible) {
        setRelatVisible(visible)
    }
    return (
        <Box sx={{
            width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 5, [theme.breakpoints.down('md')]: {
                overflow: 'scroll'
            },
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
                <Typography variant="h2" fontWeight={'bold'}>{props?.nome}</Typography>
                <Typography fontSize={20}>{props?.subtitle}</Typography>
            </Box>
            <Box sx={{ width: '90%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                {!getEvento(props.eventos, 'Acesso') ?
                    <Box sx={{ padding: 2, background: theme.palette.warning.main }}>
                        <Typography sx={{ color: theme.palette.text.whiteText, fontWeight: 'bold' }}>Você não possui acesso a esse módulo</Typography>
                    </Box>
                    :
                    <>
                        <Filter getProdutos={getProdutos} handleNewProduto={handleNewProduto} visiblePendencias={visiblePendencias} getMinhasAlterações={getMinhasAlterações} getRevisoes={getRevisoes} handleRelatVisible={handleRelatVisible} />
                        {isPendEcom ?
                            <Box sx={{ padding: 2, background: theme.palette.secondary.main }}>
                                <Typography sx={{ color: theme.palette.text.whiteText, fontWeight: 'bold', textAlign: 'center' }}>Realizando pendências do e-commerce</Typography>
                            </Box>
                            : null}
                        <ListProdutos isPendEcom={isPendEcom} produtos={produtos} updateEditProduto={updateEditProduto} handleEcomInfo={handleEcomInfo} />
                        <NovoProduto visible={novoProduto} dismiss={handleNewProduto} pushProdutos={pushProdutos} />
                        <EditProduto visible={editProduto.visible} prevProduto={editProduto.produto} updateEditProduto={updateEditProduto} updateProduto={updateProduto} />
                        <Pendencias getEcomPend={getEcomPend} visible={pendencias} visiblePendencias={visiblePendencias} />
                        <EcomView isPendEcom={isPendEcom} visible={ecomInfo.visible} prevData={ecomInfo.produto} updateProduto={updateProduto} handleEcomInfo={handleEcomInfo} />
                        <Relatorios visible={relatVisible} handleRelatVisible={handleRelatVisible} updateModalInfo={updateModalInfo} />
                    </>
                }
            </Box>
            <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={MInfo.canDesactive} visible={MInfo.visible} title={MInfo.title} subtitle={MInfo.subtitle} icon={MInfo.icon} funcS={MInfo.funcS} />
        </Box>
    );
}