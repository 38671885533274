import React, { useState, useContext, useEffect, useRef } from 'react';
import { useTheme, Box, Modal, Typography, IconButton } from '@mui/material';
import { ModalInfo } from '../../../../components/modalInfoN';
import { User } from '../../../../contexts/user';
import mainApi from '../../../../apis/mainApiN';
import { ButtonSubmit } from '../../../../components/buttons';
import { getIcon } from '../../../../components/icons';
import { CTextField } from '../../../../components/TextField';
export function ModalPostagem({ pedido, visible, prevData, allData, updatedata, dismissPostagem }) {
    const theme = useTheme()
    const fileInputRef = useRef(null);
    const user = useContext(User);
    const dataInit = {
        prePostagem: '',
        cod_rastreio: '',
        recibo: '',
        etiqueta: ''
    }
    const [data, setData] = useState(dataInit)
    const [step, setStep] = useState('Postagem')
    function handleDismiss() {
        setData(dataInit)
        dismissPostagem()
        return
    }
    const [modalInfo, setModalInfo] = useState({
        visible: false,
        title: '',
        subtitle: '',
        canDesactive: true,
        icon: '',
        funcS: () => null
    });
    function updateModalInfo(visible, canDesactive, title, subtitle, icon, func) {
        setModalInfo({
            visible,
            canDesactive,
            title,
            subtitle,
            icon,
            funcS: func
        });
    }    function changeData(value, type) {
        const newData = { ...data }
        newData[type] = value
        setData(newData)
    }
    function handleButton(type) {
        if (type === 'Sair') {
            handleDismiss()
            return
        }
        saveData()
        return
    }
    async function saveData() {
        const newAllData = { ...allData }
        const newData = { ...data }
        if (step === 'Postagem') {
            updateModalInfo(true, false, 'Criando', 'Estamos criando sua Pré-Postagem.', 'loading')
            const get = await mainApi('correiosN/createPrePostagemmp', 'POST', { pedido: pedido }, user?.data?.token)
            if (get?.status !== 200) {
                const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao criar a Pré-Postagem.'
                updateModalInfo(true, true, 'Atenção', message, 'exclamation')
                return
            }
            updateModalInfo(true, true, 'Sucesso', 'Pré-Postagem criada com sucesso.', 'sucess')
            const dados = get?.apiReturn?.apiReturn
            newData.cod_rastreio = dados.cod_rastreio
            newData.prePostagem = dados.prePostagem
            newAllData.prePostagem = dados.prePostagem
            newAllData.entrega_rastreio = dados.cod_rastreio
            setStep('Recibo')
        }
        if (step === 'Recibo') {
            updateModalInfo(true, false, 'Criando', 'Estamos criando seu recibo.', 'loading')
            const get = await mainApi('correiosN/createRecibo', 'POST', { pedido: pedido }, user?.data?.token)
            if (get?.status !== 200) {
                const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao criar o recibo.'
                updateModalInfo(true, true, 'Atenção', message, 'exclamation')
                return
            }
            const recibo = get?.apiReturn?.apiReturn
            updateModalInfo(true, true, 'Sucesso', 'Recibo criado com sucesso.', 'sucess')
            newAllData.recibo = recibo
            newData.recibo = recibo
            setStep('Etiqueta')
        }
        if (step === 'Etiqueta') {
            updateModalInfo(true, false, 'Criando', 'Estamos criando sua etiqueta.', 'loading')
            const get = await mainApi('correiosN/createEtiqueta', 'POST', { pedido: pedido }, user?.data?.token)
            if (get?.status !== 200) {
                const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao criar a etiqueta.'
                updateModalInfo(true, true, 'Atenção', message, 'exclamation')
                return
            }
            const etiqueta = get?.apiReturn?.apiReturn
            updateModalInfo(true, true, 'Sucesso', 'Etiqueta criado com sucesso.', 'sucess')
            newAllData.etiqueta = etiqueta
            newData.etiqueta = etiqueta
            setStep('Iniciar Separação')
        }
        if (step === 'Iniciar Separação') {
            updateModalInfo(true, false, 'Separação', 'Iniciando separação', 'loading')
            const get = await mainApi('pedidosEcom/initSeparacao', 'POST', { pedido: pedido }, user?.data?.token)
            if (get?.status !== 200) {
                const message = get?.apiReturn?.message ? get?.apiReturn?.message : 'Ocorreu um erro ao iniciar a separação.'
                updateModalInfo(true, true, 'Atenção', message, 'exclamation')
                return
            }
            const status = get?.apiReturn?.apiReturn
            updateModalInfo(true, true, 'Sucesso', 'Status do pedido foi trocado para "Em Separação."', 'sucess')
            newAllData.status = status.status
            newAllData.status_nome = status.status_nome
        }
        setData(newData)
        updatedata(newAllData)
    }
    function openBase64Pdf(base64) {
        const pdfWindow = window.open("");
        pdfWindow.document.write(
            `<iframe width='100%' height='100%' src='data:application/pdf;base64,${base64}'></iframe>`
        );
    }
    useEffect(() => {
        let control = 'Postagem'
        if (prevData.prePostagem) control = 'Recibo'
        if (prevData.recibo) control = 'Etiqueta'
        if (prevData.etiqueta) control = 'Iniciar Separação'
        if (prevData?.status_nome !== 'Pedido Faturado') control = null
        const newData = { ...data }
        newData.prePostagem = prevData?.prePostagem ? prevData?.prePostagem : ''
        newData.cod_rastreio = prevData?.entrega_rastreio ? prevData?.entrega_rastreio : ''
        newData.recibo = prevData?.recibo ? prevData.recibo : ''
        newData.etiqueta = prevData?.etiqueta ? prevData?.etiqueta : ''
        setData(newData)
        setStep(control)
    }, [visible, prevData])
    return (
        <Modal open={visible} onClose={handleDismiss} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }} slotProps={{
            backdrop: {
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(250,255,255, 0.2)' : 'rgba(0,0,0, 0.4)'
                }
            }
        }}>
            <Box sx={{
                display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 450, width: '95%', maxWidth: 600, background: theme.palette.background.default, outline: 'none',
                [theme.breakpoints.down('lg')]: {
                    width: '98%'
                },
            }}>
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 5, paddingBottom: 0 }}>
                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                            {getIcon(68, theme.palette.primary.main, 30)}
                            <Typography sx={{ fontWeight: 'bold', color: theme.palette.text.primary, fontSize: 30 }}>Pré-Postagem</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', padding: 5, paddingTop: 1, paddingBottom: 1 }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Informações da nota</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <CTextField disabled={true} label={'Pré-Postagem'} width={'100%'} value={data['prePostagem']} onChangeText={changeData} type={'prePostagem'} prevData={data} />
                            <CTextField disabled={true} label={'Código de Rastreio'} width={'100%'} value={data['cod_rastreio']} onChangeText={changeData} type={'cod_rastreio'} prevData={data} />
                            <CTextField disabled={true} label={'Recibo'} width={'100%'} value={data['recibo']} onChangeText={changeData} type={'recibo'} prevData={data} />
                            {data.etiqueta ?
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                    <Typography sx={{ color: theme.palette.text.primary }}>Sua etiqueta está pronta. Clique abaixo para baixá-la.</Typography>
                                    <Box>
                                        <IconButton onClick={() => openBase64Pdf(data.etiqueta)}>{getIcon(79, theme.palette.secondary.main, 30)}</IconButton>
                                    </Box>
                                </Box>
                                : null}
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: 2, padding: 5, paddingTop: 1 }}>
                    {[step, 'Sair'].map((e) => {
                        if (e === null) return
                        const background = e !== 'Sair' ? theme.palette.primary.main : theme.palette.background.paper
                        const color = e !== 'Sair' ? theme.palette.text.whiteText : theme.palette.text.primary
                        return (
                            <ButtonSubmit width={'auto'} key={e} text={e} background={background} color={color} func={handleButton} funcParameters={e} />
                        )
                    })}
                </Box>
                <ModalInfo hideModal={() => updateModalInfo(false)} canDesactive={modalInfo.canDesactive} visible={modalInfo.visible} title={modalInfo.title} subtitle={modalInfo.subtitle} icon={modalInfo.icon} funcS={modalInfo.funcS} />
            </Box>
        </Modal>
    )
}